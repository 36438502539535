import type { UnreadComment, UnreadCommentNode } from '@confluence/unread-comments';

export const toggleCommentEditor = (editorEmitter, handleCommentButtonClick) => {
	if (document.getElementById('editor-comments-sidebar')) {
		editorEmitter.emit('setselectedannotation', '');
	} else {
		handleCommentButtonClick();
	}
};

export const toggleCommentEditorWithAnnotationManagers = (
	editorEmitter,
	handleCommentButtonClick,
	editorViewProps,
	setEditorViewProps,
) => {
	// If we aren't showing/creating a comment trigger the comment button click
	if (!editorViewProps) {
		handleCommentButtonClick();
	} else {
		// Otherwise, close the comment
		editorEmitter.emit('setselectedannotation', '');
		setEditorViewProps(null);
	}
};

export const getUnreadInlineCommentsListState = (
	unreadCommentsListState: UnreadComment[],
	allComments: UnreadCommentNode[],
) => {
	if (!allComments || !unreadCommentsListState) {
		return [];
	}

	const threadKeyToTypeMap: Record<string, string> = allComments.reduce(
		(map, comment) => {
			map[comment.id] = comment.location.type;
			return map;
		},
		{} as Record<string, string>,
	);

	return unreadCommentsListState.filter((unreadComment) => {
		const type = threadKeyToTypeMap[unreadComment.id];
		return type === 'INLINE';
	});
};
