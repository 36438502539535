/* eslint-disable check-file/filename-blocklist */
import { defineMessages } from 'react-intl-next';

export const commonI18n = defineMessages({
	dismiss: {
		id: 'automation-discovery.common.dismiss',
		defaultMessage: 'Not now',
		description: 'Label for user to dismiss the message',
	},
	showMeHow: {
		id: 'automation-discovery.common.show-me-how',
		defaultMessage: 'Show me how',
		description: 'Label to prompt the user to click into the in-context rule builder',
	},
	spotlightCta: {
		id: 'automation-discovery.manual-trigger.cta.text',
		defaultMessage: 'Build rule',
		description: 'Label taking the user to set up an automation rule',
	},
	spotlightDismiss: {
		id: 'automation-discovery.manual-trigger.dismiss',
		defaultMessage: 'Done',
		description: 'Label for user to dismiss the message',
	},
});

export const hoverPageTitleI18n = defineMessages({
	header: {
		id: 'automation-discovery.hover-page-title.header',
		defaultMessage: 'Auto-archive old pages?',
		description: 'A header asking the user to automatically archiving old pages',
	},
	message: {
		id: 'automation-discovery.hover-page-title.message',
		defaultMessage:
			'This page hasn’t been updated lately. An Automation rule could archive old pages once a month.',
		description: 'Message to user suggesting how to automatically archive old pages',
	},
});

export const archiveMessagesI18n = defineMessages({
	header: {
		id: 'automation-discovery.archive.from-archive-modal',
		defaultMessage: 'Confluence can auto-archive old pages',
		description: 'A header telling the user about automatically archiving old pages',
	},
	message: {
		id: 'automation-discovery.archive.message',
		defaultMessage:
			// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
			// eslint-disable-next-line no-restricted-syntax
			"Try building this Automation rule: Archive pages in this space that haven't been updated in at least 3 months.",
		description: 'Message to use to build an automation rule that archives old pages in a space',
	},
	success: {
		id: 'automation-discovery.archive.success',
		defaultMessage: 'Your page was archived',
		description: 'Message to user confirming the page was archived successfully',
	},
	successHeader: {
		id: 'automation-discovery.archive.success-header',
		defaultMessage: 'Archived successfully',
		description: 'A header message to user confirming the page was archived successfully',
	},
	viewInArchive: {
		id: 'automation-discovery.archive.view-in-archive',
		defaultMessage: 'View in archive',
		description: 'A link to the archive page, which has a list of all archived items',
	},
});

export const labelMessagesI18n = defineMessages({
	title: {
		id: 'automation-discovery.add-label.title',
		defaultMessage: 'Confluence can auto-add labels',
		description: 'Message to user about automating adding labels',
	},
	message: {
		id: 'automation-discovery.add-label.message',
		defaultMessage:
			'Try building this Automation rule: When the user you choose publishes a new page, label it with their name.',
		description: 'Message to user to try building automation rule that add labels',
	},
});

export const meetingNotesI18n = defineMessages({
	success: {
		id: 'automation-discovery.meeting-notes.success',
		defaultMessage: 'Your page was published',
		description: 'Message to user about the page was published successfully',
	},
	title: {
		id: 'automation-discovery.meeting-notes.title',
		defaultMessage: 'Confluence can auto-publish pages',
		description: 'Message to user about Automation can auto-publish pages',
	},
	message: {
		id: 'automation-discovery.meeting-notes.message',
		defaultMessage:
			'Try building this Automation rule: Publish a new Meeting notes page according to your meeting schedule.',
		description: 'Message to user to try building automation rule that auto publish meeting notes',
	},
	ctaText: {
		id: 'automation-discovery.meeting-notes.cta.text',
		defaultMessage: 'Auto-summarize pages?',
		description: 'Asking the user if they want to try to automate summarizing pages in their space',
	},
	modalBody: {
		id: 'automation-discovery.meeting-notes.modal.body',
		defaultMessage: '{publishedPageTitle} was successfully published in the {spaceName} space',
		description: 'Message informing the user they successfully published a page in their space',
	},
	successIconLabel: {
		id: 'automation-discovery.meeting-notes.success.icon.label',
		defaultMessage: 'Success check icon',
		description: 'accessibility label to describe a green success check icon',
	},
	modalCloseIconLabel: {
		id: 'automation-discovery.meeting-notes.modal.close.icon.label',
		defaultMessage: 'Close icon for modal',
		description: 'accessibility label to describe a cross icon to close the modal',
	},
});

export const jiraLinkI18n = defineMessages({
	ctaText: {
		id: 'automation-discovery.jira-link.cta.text',
		defaultMessage: 'Auto-link Jira issues?',
		description: 'Asking the user if they want to try to automate linking Jira issues',
	},
});

export const manualTriggerI18n = defineMessages({
	title: {
		id: 'automation-discovery.manual-trigger.title',
		defaultMessage: 'Page automation menu',
		description:
			'A header telling the user about the page automation menu in the Confluence editor',
	},
	body: {
		id: 'automation-discovery.manual-trigger.body',
		defaultMessage: 'Teammates can apply rules from this menu to a single page.',
		description: 'A message telling the user about the page automation menu functionality',
	},
});

export const verifiedContentI18n = defineMessages({
	title: {
		id: 'automation-discovery.verified-content.title',
		defaultMessage: 'Want to manage your verified pages?',
		description: 'A header asking the user if they would like automation to manage verified pages.',
	},
	body: {
		id: 'automation-discovery.verified-content.body',
		defaultMessage: 'Use Confluence automation to schedule reviews for verified content.',
		description: 'A message telling the user about an automation rule to manage verified content.',
	},
});

export const productivityInsightsI18n = defineMessages({
	productivityTimeSavedMessage: {
		id: 'automation-discovery.productivity-insights.time-saved.message',
		defaultMessage:
			'Save your team <b>{timeSaved, plural, one {{timeSaved} minute} other {{timeSaved} minutes}}</b> per quarter by setting up this automation rule!',
		description:
			'A prompt telling the user how many minutes of work could potentially be saved per quarter by enabling an automation. The timeSaved variable will be a number.',
	},
});
