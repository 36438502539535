export enum EventSourceName {
	ADD_LABEL_TO_PAGE = 'automationDiscoveryWhenLabelAddedToPage',
	MEETING_NOTES = 'automationDiscoveryWhenPublishingMeetingNotes',
	ARCHIVE_PAGE = 'automationDiscoveryWhenArchivingPage',
	HOVER_OVER_PAGE_TITLE = 'automationDiscoveryFromHoverOverPageTitle',
	JIRA_LINK = 'automationDiscoveryJiraLink',
	MANUAL_TRIGGER = 'automationDiscoveryManualTrigger',
	MANUAL_TRIGGER_SMART_BUTTON = 'automationDiscoveryManualTriggerSmartButton',
	IN_CONTEXT_RULE_BUILDER = 'automationDiscoveryInContextRuleBuilder',
	VERIFED_CONTENT = 'automationDiscoveryVerifiedContent',
	BULK_ARCHIVE = 'automationDiscoveryBulkArchive',
	GLOBAL_PUBLIC_LINKS = 'automationDiscoveryGlobalPermissions',
	PAGE_PUBLIC_LINKS = 'automationDiscoverPageShare',
	BULK_ASSIGN = 'automationDiscoverySpaceAssignedBulk',
	SINGLE_ASSIGN = 'automationDiscoverySpaceAssignedSingle',
	DEFAULT_SPACE_ASSIGN = 'automationDiscoveryDefaultSpaceAssigned',
	WHITE_BOARDS = 'automationDiscoveryPublishWhiteBoards',
	SHARE_SLACK = 'automationDiscoveryShareSlack',
	BULK_CHANGE_OWNER = 'automationDiscoveryBulkChangePageOwner',
	PRODUCT_REQUIREMENTS = 'automationDiscoveryProductRequirements',
	FEEDBACK_ANALYSIS = 'automationDiscoveryFeedbackAnalysis',
	RELEASE_NOTES = 'automationDiscoveryReleaseNotes',
}
