import React, { forwardRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Tooltip from '@atlaskit/tooltip';

import {
	GeneralShortcutListener,
	ShortcutVisualizer,
	AUTOMATION_SHORTCUT,
} from '@confluence/shortcuts';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import type { TriggerProps } from '../../types';

import { AutomationMenuButtonWithSmartButtonSpotlight } from './AutomationMenuButtonWithSmartButtonSpotlight';
import { AutomationMenuButtonWithSpotlight } from './AutomationMenuButtonWithSpotlight';

export const i18n = defineMessages({
	automationGroupedPageButtonsTooltip: {
		id: 'automation-menu.automation-grouped-page-button.tooltip',
		description:
			'The tooltip message that appears when a user hovers on the button for Automation.',
		defaultMessage: 'Automation',
	},
});

export type AutomationGroupedPageButtonProps = TriggerProps;

export const AutomationGroupedPageButton = forwardRef<
	HTMLElement,
	AutomationGroupedPageButtonProps
>(({ isSelected, onClick }: AutomationGroupedPageButtonProps, ref) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();
	const isOnEditRoute = useIsEditorPage();

	const onClickWithAnalytics = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'automationGroupedPageButton',
				source: 'viewPageScreen',
			},
		}).fire();
		onClick();
	};

	const tooltipContent =
		isLiveEditMode || (isOnEditRoute && fg('confluence_frontend_edit_page_keyboard_shortcut')) ? (
			formatMessage(i18n.automationGroupedPageButtonsTooltip)
		) : (
			<ShortcutVisualizer
				shortcut={AUTOMATION_SHORTCUT}
				contentBefore={formatMessage(i18n.automationGroupedPageButtonsTooltip)}
			/>
		);

	return (
		<>
			<Tooltip content={tooltipContent} position="bottom">
				{fg('cc_a4c_smart_button_changeboarding') ? (
					<AutomationMenuButtonWithSmartButtonSpotlight
						isSelected={isSelected}
						onClick={onClick}
						onClickWithAnalytics={onClickWithAnalytics}
						ref={ref}
					/>
				) : (
					<AutomationMenuButtonWithSpotlight
						isSelected={isSelected}
						onClick={onClick}
						onClickWithAnalytics={onClickWithAnalytics}
						ref={ref}
					/>
				)}
			</Tooltip>
			{!isLiveEditMode && !isOnEditRoute && (
				<GeneralShortcutListener
					accelerator={AUTOMATION_SHORTCUT}
					listener={onClickWithAnalytics}
				/>
			)}
		</>
	);
});
