import React from 'react';

import { ConfluencePageAri } from '@atlassian/ari';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';

import { AutomationPopup } from './automation-popup/AutomationPopup';
import { AutomationGroupedPageButton } from './automation-popup/triggers/AutomationGroupedPageButton';
import type { PageGroupedButtonsAutomationMenuProps } from './types';

export const PageGroupedButtonsAutomationMenu = (props: PageGroupedButtonsAutomationMenuProps) => {
	const { pageId } = props;

	const { cloudId } = useSessionData();
	const pageAri = ConfluencePageAri.create({ siteId: cloudId, pageId });

	// Get the state telling if the current page is live-edit page or not
	const isLivePage = useIsCurrentPageLive();

	return (
		<ErrorBoundary attribution={Attribution.AUTOMATION_FOR_CONFLUENCE}>
			<AutomationPopup
				{...props}
				aris={[pageAri]}
				analyticsSource="pageGroupedButtonsManualTriggers"
				Trigger={AutomationGroupedPageButton}
				customAnalyticsAttributes={{ isLivePage }}
			/>
		</ErrorBoundary>
	);
};
